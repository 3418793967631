import { useContext, useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { CREATE_PAGE_EXPERIENCE, ExperienceTrackerContext } from '@confluence/experience-tracker';
import type { OnContentUpdatedResponse } from '@confluence/create-dialog-context';
import { CreateDialogContext } from '@confluence/create-dialog-context';
import { CREATE_PAGE_VARIABLES_ACTION_LEGACY } from '@confluence/named-routes';
import { getApolloClient } from '@confluence/graphql';
import {
	CREATED_WITH_TEMPLATE_QUERY_PARAM_NAME,
	IN_EDITOR_TEMPLATES_QUERY_OPEN,
	IN_EDITOR_TEMPLATES_QUERY_PARAM_NAME,
} from '@confluence/template-utils';
import { addQueryParams } from '@confluence/route-manager';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import type {
	Creatable,
	CanHandle,
	Handle,
	TemplateBodyDetails,
} from '../createContentFromTemplateTypes';
import { processDraftUrl } from '../helpers';
import type {
	CreatePageByTemplateMutation as CreateFromTemplateResponse,
	CreatePageByTemplateMutationVariables,
} from '../queries/__types__/CreatePageByTemplateMutation';
import { CreatePageByTemplateMutation } from '../queries/CreatePageByTemplate.experimentalgraphql';
import type {
	GetTemplateBodyContentQuery as GetTemplateBodyResponse,
	GetTemplateBodyContentQueryVariables,
} from '../queries/__types__/GetTemplateBodyContentQuery';
import { GetTemplateBodyContentQuery } from '../queries/GetTemplateBodyContent.graphql';
import type {
	UpdateDraftByTemplateMutation as UpdateDraftByTemplateResponse,
	UpdateDraftByTemplateMutationVariables,
} from '../queries/__types__/UpdateDraftByTemplateMutation';
import { UpdateDraftByTemplateMutation } from '../queries/UpdateDraftByTemplate.experimentalgraphql';

import { useNavigation } from './useNavigation';

const getTemplateBodyDetails = async (templateId: string): Promise<TemplateBodyDetails> => {
	// this has to be done via getApolloClient() because neither useQuery or useLazyQuery return a promise with the data, they return void.
	const templateBodyResponse = (
		await getApolloClient().query<GetTemplateBodyResponse, GetTemplateBodyContentQueryVariables>({
			query: GetTemplateBodyContentQuery,
			variables: {
				templateId,
			},
		})
	).data;

	const adfString = templateBodyResponse?.template?.body?.atlas_doc_format?.value || '';

	const hasVariables = adfString.indexOf('com.atlassian.confluence.template') !== -1;

	const editorVersion = templateBodyResponse?.template?.editorVersion || '';

	return { hasVariables, editorVersion };
};

export const useTemplateCreatable = (): Creatable => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const navigate = useNavigation();
	const { openTemplateVariables } = useContext(CreateDialogContext);
	const isEditorPage = useIsEditorPage();

	const [createFromTemplateMutation] = useMutation<
		CreateFromTemplateResponse,
		CreatePageByTemplateMutationVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CreatePageByTemplateMutation,
	);

	const [updateFromTemplateMutation] = useMutation<
		UpdateDraftByTemplateResponse,
		UpdateDraftByTemplateMutationVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		UpdateDraftByTemplateMutation,
	);

	const canHandle: CanHandle = useCallback((template) => !!template.templateId, []);

	const handle: Handle = useCallback(
		async (template, spaceKey, options = {}) => {
			// casting because i know it exists from canHandle
			const templateId = template.templateId as string;
			const {
				contentId,
				parentPageId,
				title,
				subType,
				onContentUpdated,
				onVariableInputError,
				beforeUpdateRequest,
				forceTemplatePanelOpen,
				openContentInNewTab,
			} = options;

			const { hasVariables, editorVersion } = await getTemplateBodyDetails(templateId);
			if (hasVariables) {
				const onContentUpdatedWrapper = (response: OnContentUpdatedResponse) => {
					if (onContentUpdated) {
						onContentUpdated(response, template, spaceKey, contentId || '');
					}
				};

				if (editorVersion === 'v2') {
					openTemplateVariables({
						templateId,
						spaceKey,
						parentPageId: parentPageId || '',
						title: title || '',
						beforeUpdateRequest,
						forceTemplatePanelOpen,
						openContentInNewTab,
						...(contentId && { contentId }),
						...(onContentUpdated && {
							onContentUpdated: onContentUpdatedWrapper,
						}),
						...(onVariableInputError && { onError: onVariableInputError }),
					});
				} else {
					const legacyVariablesUrl = CREATE_PAGE_VARIABLES_ACTION_LEGACY.toUrl(
						{},
						{
							query: {
								templateId,
								spaceKey,
								title: title || '',
								newSpaceKey: spaceKey,
								fromPageId: parentPageId || '',
								// Try to get window.AJS if it doesn't exist, return nothing
								// @ts-ignore
								atl_token: (window as any).AJS?.Meta?.get('atl-token') || '',
							},
						},
					);

					// use forceReload because the SPA routing for this legacy page is broken
					navigate(legacyVariablesUrl, {
						openContentInNewTab,
						forceReload: true,
					});

					experienceTracker.succeed({ name: CREATE_PAGE_EXPERIENCE });
				}
				return false;
			} else if (contentId) {
				beforeUpdateRequest && beforeUpdateRequest();
				const updateFromTemplateResponse = await updateFromTemplateMutation({
					variables: {
						contentId,
						spaceKey,
						title,
						blueprint: JSON.stringify(template),
					},
				});
				const response = updateFromTemplateResponse?.data?.experimentalUpdateDraftWithTemplate;
				const responseEditorVersion = response?.content?.metadata?.properties?.editor?.value;

				if (responseEditorVersion === 'v2' && onContentUpdated && response) {
					onContentUpdated(response, template, spaceKey, contentId);
				} else {
					const draftUrl = response?.content?._links?.editui;
					navigate(processDraftUrl(draftUrl), {
						isReplaceAction: true,
					});
				}
			} else {
				// ToDo: Set attemptSpaUrlResponse to always be true and verify that there are no longer editor->editor
				//  transition bugs (https://product-fabric.atlassian.net/browse/MODES-3741)
				//  remove the call to isEditorPage if successful
				const createFromTemplateResponse = await createFromTemplateMutation({
					variables: {
						spaceKey,
						parentPageId,
						title,
						subType,
						blueprint: JSON.stringify(template),
						attemptSpaUrlResponse: !isEditorPage,
					},
				});
				const draftUrl =
					createFromTemplateResponse?.data?.experimentalPublishPageByTemplate?.links?.draftUrl;
				if (forceTemplatePanelOpen) {
					navigate(
						addQueryParams(processDraftUrl(draftUrl), {
							[IN_EDITOR_TEMPLATES_QUERY_PARAM_NAME]: IN_EDITOR_TEMPLATES_QUERY_OPEN,
							[CREATED_WITH_TEMPLATE_QUERY_PARAM_NAME]: 'true',
						}),
						{
							openContentInNewTab,
						},
					);
				} else {
					navigate(
						addQueryParams(processDraftUrl(draftUrl), {
							[CREATED_WITH_TEMPLATE_QUERY_PARAM_NAME]: 'true',
						}),
						{
							openContentInNewTab,
						},
					);
				}
				experienceTracker.succeed({ name: CREATE_PAGE_EXPERIENCE });
			}
			return true;
		},
		[
			createFromTemplateMutation,
			updateFromTemplateMutation,
			isEditorPage,
			experienceTracker,
			navigate,
			openTemplateVariables,
		],
	);

	return useMemo(() => ({ canHandle, handle }), [canHandle, handle]);
};
