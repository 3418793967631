import gql from 'graphql-tag';

export const CreatePageByBlueprintMutation = gql`
	mutation CreatePageByBlueprintMutation(
		$spaceKey: String!
		$blueprint: String!
		$parentPageId: String
		$title: String
		$subType: String
		$attemptSpaUrlResponse: Boolean
	) @experimental {
		experimentalPublishPageByBlueprint(
			spaceKey: $spaceKey
			contentId: $parentPageId
			blueprint: $blueprint
			title: $title
			subType: $subType
			attemptSpaUrlResponse: $attemptSpaUrlResponse
		) {
			links {
				draftUrl
			}
		}
	}
`;
