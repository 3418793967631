import gql from 'graphql-tag';

export const CreatePageByTemplateMutation = gql`
	mutation CreatePageByTemplateMutation(
		$spaceKey: String!
		$blueprint: String!
		$parentPageId: String
		$title: String
		$subType: String
		$attemptSpaUrlResponse: Boolean
	) @experimental {
		experimentalPublishPageByTemplate(
			spaceKey: $spaceKey
			contentId: $parentPageId
			blueprint: $blueprint
			title: $title
			subType: $subType
			attemptSpaUrlResponse: $attemptSpaUrlResponse
		) {
			links {
				draftUrl
			}
		}
	}
`;
